@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    :root{
        @apply scroll-smooth;
    }
    body{
        @apply bg-slate-50 selection:bg-[#7689de] selection:text-white;
        font-family: 'Bitter', serif;
    }
    
    .flip-card {
        perspective: 1000px;
    }

    .flip-card-inner {
        transform-style: preserve-3d;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    }

    .flip-card:hover .flip-card-inner {
        transform: rotateY(180deg);
    }

    .flip-card-front, .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .flip-card-back {
        transform: rotateY(180deg);
    }
}